import React, { useState, useRef } from 'react';
import { ModalBody } from 'reactstrap';
import * as Styled from './ModalSaldo.styles';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInputMoney from '../../../components/TextInputMoney/TextInputMoney';
import DateInput from '../../../components/DateInput/DateInput';
import operateBalanceValidation from './validation/operateBalance.validation';
import Button from '../../../components/Button/Button.style';
import ModalPassword from '../DuasEtapas/PasswordConfirmation';
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { getTransactionsType } from '../../../store/transactions/actions';
import api from '../../../service/api';

const ModalSaldo = (props) => {
  const dispatch = useDispatch();
  const {
    user: { user },
    transactions: { modoPagamento },
    swap: { banks },
  } = useSelector((state) => state);
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);

  const [operateBalanceData, setOperateBalanceData] = useState({
    VALOR: '',
    TIPO: '',
    DESCRICAO: '',
    DATA: new Date().toISOString().slice(0, 10),
    MODO_PAGAMENTO: 0,
    CONTA: user.CONTA_BACEN.length > 0 && user.CONTA_BACEN[0].UUID,
    CPFCNPJ_TRANSFERENCIA: '',
    CONTA_TRANSFERENCIA: '',
    DIGITO_TRANSFERENCIA: '',
    AGENCIA_TRANSFERENCIA: '',
    BANCO_TRANSFERENCIA: '',
    NOME_TRANSFERENCIA: '',
    TIPO_CONTA_TRANSFERENCIA: '',
  });

  const inputMoneyRef = useRef(null);
  const [contas, setContas] = useState([]);

  const { modal, toggle, dados, option } = props;

  const {
    handleSubmit: handleOperateBalance,
    control: control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(operateBalanceValidation),
  });

  const handleToggle = () => {
    setOperateBalanceData({
      VALOR: '',
      TIPO: '',
      DESCRICAO: '',
      DATA: new Date().toISOString().slice(0, 10),
      MODO_PAGAMENTO: 0,
      CONTA: user.CONTA_BACEN.length > 0 && user.CONTA_BACEN[0].UUID,
      CPFCNPJ_TRANSFERENCIA: '',
      CONTA_TRANSFERENCIA: '',
      DIGITO_TRANSFERENCIA: '',
      AGENCIA_TRANSFERENCIA: '',
      BANCO_TRANSFERENCIA: '',
      NOME_TRANSFERENCIA: '',
      TIPO_CONTA_TRANSFERENCIA: '',
    });
    reset();
    toggle();
  };

  const handleChange = ({ target: { name, value } }) => {
    setOperateBalanceData({ ...operateBalanceData, [name]: value });
  };

  useEffect(() => {
    if (modal) api.get('conta_bancaria_bacen').then(({ data }) => setContas(data.data));
  }, [modal]);

  useEffect(() => {
    dispatch(getTransactionsType());
  }, []);

  const openModalPassword = (data) => {
    if (operateBalanceData.MODO_PAGAMENTO > 0) {
      if (operateBalanceData) setIsModalPasswordOpen(true);
      setOperateBalanceData({ ...operateBalanceData, VALOR: data.VALOR });
    }
  };

  const renderFormModoPagamento = () => {
    switch (+operateBalanceData.MODO_PAGAMENTO) {
      case 5:
        return (
          <Box>
            <Box>
              <Typography>Banco</Typography>
              <Select
                name="BANCO_TRANSFERENCIA"
                fullWidth
                onChange={handleChange}
                value={operateBalanceData.BANCO_TRANSFERENCIA}
                required
              >
                {banks.map((bank) => (
                  <MenuItem key={bank.CODBANCO} value={bank.CODBANCO}>
                    {bank.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Typography>CPFCNPJ</Typography>
              <TextField
                name="CPFCNPJ_TRANSFERENCIA"
                onChange={handleChange}
                value={operateBalanceData.CPFCNPJ_TRANSFERENCIA}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Typography>Nome</Typography>
              <TextField
                name="NOME_TRANSFERENCIA"
                onChange={handleChange}
                value={operateBalanceData.NOME_TRANSFERENCIA}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Typography>Conta</Typography>
              <TextField
                name="CONTA_TRANSFERENCIA"
                onChange={handleChange}
                value={operateBalanceData.CONTA_TRANSFERENCIA}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Typography>Dígito</Typography>
              <TextField
                name="DIGITO_TRANSFERENCIA"
                onChange={handleChange}
                value={operateBalanceData.DIGITO_TRANSFERENCIA}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Typography>Agência</Typography>
              <TextField
                name="AGENCIA_TRANSFERENCIA"
                onChange={handleChange}
                value={operateBalanceData.AGENCIA_TRANSFERENCIA}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Typography>Tipo Conta</Typography>
              <TextField
                name="TIPO_CONTA_TRANSFERENCIA"
                onChange={handleChange}
                value={operateBalanceData.TIPO_CONTA_TRANSFERENCIA}
                required
                fullWidth
              />
            </Box>
          </Box>
        );
      case 16:
        return (
          <Box>
            <Typography>Conta Transferência:</Typography>
            <Select
              name="CONTA_TRANSFERENCIA"
              onChange={handleChange}
              value={operateBalanceData.CONTA_TRANSFERENCIA}
              required
              fullWidth
            >
              {contas &&
                contas.length > 0 &&
                contas.map((conta) => (
                  <MenuItem key={conta.UUID} value={conta.UUID}>
                    {conta.CONTA}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        );
      default:
        return;
    }
  };

  return (
    <Styled.ModalSaldo isOpen={modal} toggle={handleToggle}>
      <ModalBody>
        <Styled.Container>
          <Styled.ContainerBox>
            <div className="titleBox">Operar Saldo Cliente</div>
            <Styled.Form onSubmit={handleOperateBalance(openModalPassword)}>
              {user.CONTA_BACEN && user.CONTA_BACEN.length > 0 && (
                <Box>
                  <Typography>Conta</Typography>
                  <TextField
                    select
                    fullWidth
                    name="CONTA"
                    value={operateBalanceData.CONTA}
                    control={control}
                    onChange={handleChange}
                    required
                  >
                    {user.CONTA_BACEN.map((conta) => (
                      <MenuItem key={conta.UUID} value={conta.UUID}>
                        {conta.CONTA}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )}

              <Box>
                <Typography>Valor</Typography>
                <TextInputMoney
                  name={'VALOR'}
                  width={'29.706vw'}
                  margin={'0 0 1vw 0'}
                  required={true}
                  control={control}
                  error={errors.VALOR && true}
                  inputProps={{ ref: inputMoneyRef }}
                />
              </Box>
              <Box>
                <Typography>Data</Typography>
                <DateInput
                  name="DATA"
                  margin="1vw 0 1vw 0"
                  width="29.706vw"
                  onChange={handleChange}
                  disabled
                  value={operateBalanceData.DATA}
                  control={control}
                />
              </Box>
              <Box>
                <Typography>Descrição</Typography>
                <TextField
                  name="DESCRICAO"
                  margin="0 0 1vw 0"
                  onChange={handleChange}
                  multiline
                  fullWidth
                  value={operateBalanceData.DESCRICAO}
                  variant="standard"
                  type="text"
                  control={control}
                  required
                />
              </Box>
              {modoPagamento && modoPagamento.length > 0 && (
                <Box>
                  <Typography>Modo Pagamento</Typography>
                  <Select
                    value={operateBalanceData.MODO_PAGAMENTO}
                    onChange={handleChange}
                    required
                    label="Modo Pagamento"
                    labelId="select-modo-pagamento-label"
                    fullWidth
                    name="MODO_PAGAMENTO"
                  >
                    <MenuItem value={0}>Selecione o modo pagamento</MenuItem>
                    {modoPagamento
                      .filter(
                        (pagamento) =>
                          +pagamento.CODTIPO === 5 || +pagamento.CODTIPO === 16 || +pagamento.CODTIPO === 9,
                      )
                      .map((pagamento) => (
                        <MenuItem key={pagamento.CODTIPO} value={pagamento.CODTIPO}>
                          {pagamento.NOMECLATURA}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              )}
              {renderFormModoPagamento()}
              <div className="buttons">
                <Button
                  backgroundColor="#0CB42A"
                  width="9.306vw"
                  margin="2vw 0 0 0"
                  borderColor="#0CB42A"
                  color="white"
                  type="submit"
                  value="Adicionar"
                  name="TIPO"
                  onClick={handleChange}
                >
                  Adicionar
                </Button>
                <Button
                  backgroundColor="#EA6852"
                  width="9.306vw"
                  margin="2vw 0 0 0"
                  borderColor="#EA6852"
                  color="white"
                  name="TIPO"
                  type="submit"
                  value="Debitar"
                  onClick={handleChange}
                >
                  Debitar
                </Button>
                <Button
                  backgroundColor="#2405F2"
                  width="9.306vw"
                  margin="2vw 0 0 0"
                  borderColor="#2405F2"
                  color="white"
                  onClick={handleToggle}
                  block
                >
                  Voltar
                </Button>
              </div>
            </Styled.Form>

            <ModalPassword
              modal={isModalPasswordOpen}
              toggle={() => {
                setIsModalPasswordOpen(false);
                toggle();
              }}
              marginTop={'27.2315vh'}
              balance={operateBalanceData}
            />
          </Styled.ContainerBox>
        </Styled.Container>
      </ModalBody>
    </Styled.ModalSaldo>
  );
};

export default ModalSaldo;
